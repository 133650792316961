import State from './State';

class Item extends State {
  constructor(node, options = {}) {
    super({
      active: false,
    });

    this.props = {
      onInview: () => {},
      ...options,
    };

    this.node = node;
    this.node.decorator = this;

    const url = new URL(this.node.href);
    this.target = document.getElementById(url.hash.substring(1));

    this.observer = new IntersectionObserver(this.onIntersection, {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    });

    if (this.target) {
      this.create();
    }
  }

  create = () => {
    this.observer.observe(this.target);
  };

  destroy = () => {
    this.observer.unobserve(this.target);
  };

  onIntersection = entries => {
    entries.forEach(entry => {
      this.props.onInview(
        entry.isIntersecting && entry.intersectionRatio >= 0.5
      );
    });
  };

  render = () => {
    const { active } = this.state;
    if (active) {
      this.node.classList.add('active');
    } else {
      this.node.classList.remove('active');
    }
  };
}

class AnchorMenu extends State {
  constructor(node, options = {}) {
    super({
      active: null,
    });

    this.props = {
      ...options,
    };

    this.node = node;

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof AnchorMenu) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;
    this.items = [];

    this.create();
  }

  create = () => {
    this.items = Array.from(this.node.querySelectorAll('a')).map(
      (node, index) =>
        new Item(node, {
          onInview: inview => {
            if (inview) {
              this.setState({ active: index });
            }
          },
        })
    );
  };

  destroy = () => {
    this.items.forEach(item => {
      item.destroy();
    });

    this.items = [];
  };

  render = () => {
    const { active } = this.state;

    this.items.forEach((item, index) => {
      if (index === active) {
        item.setState({ active: true });
      } else {
        item.setState({ active: false });
      }
    });
  };
}

export default (node, props) => new AnchorMenu(node, props);
