/*
 * This file is part of the Nsr Stem Utdanning 2022 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the ExternalLink component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
function ExternalLink({ title }) {
  return (
    <span className="svg svg-external-link">
      <span>
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <path
            d="M9.16667 2.16667C8.70643 2.16667 8.33333 1.79357 8.33333 1.33333C8.33333 0.873096 8.70643 0.5 9.16667 0.5H14.1667C14.3877 0.5 14.5996 0.587797 14.7559 0.744078C14.9122 0.900358 15 1.11232 15 1.33333L15 6.33333C15 6.79357 14.6269 7.16667 14.1667 7.16667C13.7064 7.16667 13.3333 6.79357 13.3333 6.33333L13.3333 3.34518L5.58926 11.0893C5.26382 11.4147 4.73618 11.4147 4.41074 11.0893C4.08531 10.7638 4.08531 10.2362 4.41074 9.91074L12.1548 2.16667H9.16667ZM0 3.83333C0 2.91286 0.746192 2.16667 1.66667 2.16667H5.83333C6.29357 2.16667 6.66667 2.53976 6.66667 3C6.66667 3.46024 6.29357 3.83333 5.83333 3.83333H1.66667V13.8333H11.6667V9.66667C11.6667 9.20643 12.0398 8.83333 12.5 8.83333C12.9602 8.83333 13.3333 9.20643 13.3333 9.66667V13.8333C13.3333 14.7538 12.5871 15.5 11.6667 15.5H1.66667C0.746192 15.5 0 14.7538 0 13.8333V3.83333Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
ExternalLink.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
ExternalLink.defaultProps = {
  title: 'External link',
};

export default ExternalLink;
