class HomepageAudience {
  constructor(node, options = {}) {
    this.props = {
      ...options,
    };

    this.node = node;
    this.entries = node.querySelector('.entries');

    /* prevent double decoration */
    if (this.node.decorator) {
      if (this.node.decorator instanceof HomepageAudience) {
        this.node.decorator.destroy();
      }
    }

    this.node.decorator = this;

    this.state = {
      open: false,
    };

    this.create();
  }

  setState = state => {
    let newState = {};

    if (typeof state === 'function') {
      newState = {
        ...this.state,
        ...state(this.state),
      };
    } else {
      newState = {
        ...this.state,
        ...state,
      };
    }

    if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
      this.state = newState;
      this.render();
    }
  };

  onTransitionend = e => {
    if (e.target === this.node) {
      const style = getComputedStyle(this.entries);
      const maxHeight = parseInt(style.maxHeight);
      this.setState({ open: maxHeight > 0 });
    }
  };

  onMouseleave = () => {
    this.setState({ open: false });
  };

  create = () => {
    this.node.addEventListener('mouseleave', this.onMouseleave);
    this.node.addEventListener('transitionend', this.onTransitionend);
  };

  destroy = () => {
    this.node.removeEventListener('mouseleave', this.onMouseleave);
    this.node.removeEventListener('transitionend', this.onTransitionend);
  };

  render = () => {
    if (this.state.open) {
      this.entries.style.overflow = 'auto';
    } else {
      this.entries.style.overflow = '';
    }
  };
}

export default (node, props) => new HomepageAudience(node, props);
