/*
 * This file is part of the Nsr Stem Utdanning 2022 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * This is the Magnifier component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
function Magnifier({ title }) {
  return (
    <span className="svg svg-square svg-magnifier">
      <span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9358 12.1667H13.7917L19.1975 17.5833L17.5833 19.1975L12.1667 13.7917V12.9358L11.8742 12.6325C10.6002 13.7309 8.97376 14.3345 7.29167 14.3333C5.89896 14.3333 4.53753 13.9204 3.37953 13.1466C2.22153 12.3729 1.31899 11.2731 0.786018 9.9864C0.253051 8.6997 0.113603 7.28386 0.385307 5.91791C0.657011 4.55196 1.32766 3.29725 2.31246 2.31246C3.29725 1.32766 4.55196 0.657011 5.91791 0.385307C7.28386 0.113603 8.6997 0.253051 9.9864 0.786018C11.2731 1.31899 12.3729 2.22153 13.1466 3.37953C13.9204 4.53753 14.3333 5.89896 14.3333 7.29167C14.3333 9.03584 13.6942 10.6392 12.6325 11.8742L12.9358 12.1667ZM2.41667 7.29167C2.41667 9.98917 4.59417 12.1667 7.29167 12.1667C9.98917 12.1667 12.1667 9.98917 12.1667 7.29167C12.1667 4.59417 9.98917 2.41667 7.29167 2.41667C4.59417 2.41667 2.41667 4.59417 2.41667 7.29167Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </span>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Magnifier.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Magnifier.defaultProps = {
  title: 'Magnifier',
};

export default Magnifier;
