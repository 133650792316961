/*
 * This file is part of the Nsr Stem Utdanning 2022 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Hamburger.scss';

/**
 * This is the Hamburger component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
function Hamburger({ open }) {
  return (
    <div className={classnames('hamburger', { open })}>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Hamburger.propTypes = {
  open: PropTypes.bool,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Hamburger.defaultProps = {
  open: false,
};

export default Hamburger;
