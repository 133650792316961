class State {
  constructor(defaultState = {}) {
    this.state = defaultState;
    this.render();
  }

  setState = state => {
    let newState = {};

    if (typeof state === 'function') {
      newState = {
        ...this.state,
        ...state(this.state),
      };
    } else {
      newState = {
        ...this.state,
        ...state,
      };
    }

    if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
      this.state = newState;
      this.render();
    }
  };

  render = () => {};
}

export default State;
