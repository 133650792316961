import { useRef, useState, useEffect } from 'react';
import useKeys from 'hooks/keys';
import useMount from 'hooks/mount';
const letters = 'abcdefghijklmnopqrstuvwxyzæøå'.split('');

export default (key = 'menu', enabled = true) => {
  const letterKeys = {};
  const button = useRef(null);
  const menu = useRef(null);
  const isMount = useMount();
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function onFocus() {
      setActive(true);
    }

    if (button.current && enabled) {
      button.current.addEventListener('focus', onFocus);
    }

    return function cleanup() {
      if (button.current) {
        button.current.removeEventListener('focus', onFocus);
      }
    };
  }, [enabled]);

  useEffect(() => {
    function onClick() {
      setOpen(open => !open);
    }

    if (button.current && enabled) {
      button.current.addEventListener('click', onClick);
    }

    return function cleanup() {
      if (button.current) {
        button.current.removeEventListener('click', onClick);
      }
    };
  }, [enabled]);

  useEffect(() => {
    function onFocus({ target }) {
      if (
        button.current &&
        menu.current &&
        target !== button.current &&
        target !== menu.current &&
        !menu.current.contains(target)
      ) {
        setActive(false);
        setOpen(false);
      }
    }

    if (enabled && button.current && menu.current && active) {
      document.body.addEventListener('focusin', onFocus);
    }

    return function cleanup() {
      document.body.removeEventListener('focusin', onFocus);
    };
  }, [enabled, active]);

  useEffect(() => {
    if (enabled && open) {
      focusOnFirstItem();
    }
  }, [open, enabled]);

  function focusOnFirstItem() {
    const first = menu.current.querySelector(
      '[role="menuitem"]:not([tabindex="-1"])'
    );

    if (first && enabled) {
      first.focus();
    }
  }

  function focusOnLastItem() {
    const items = menu.current.querySelectorAll(
      '[role="menuitem"]:not([tabindex="-1"])'
    );

    const last = items[items.length - 1];
    if (last && enabled) {
      last.focus();
    }
  }

  function focusOnNextItem() {
    let current = -1;
    const items = menu.current.querySelectorAll(
      '[role="menuitem"]:not([tabindex="-1"])'
    );

    Array.from(items).forEach((item, i) => {
      if (item === document.activeElement) {
        current = i;
      }
    });

    current += 1;

    if (current >= items.length) {
      current = 0;
    }

    if (items[current] && enabled) {
      items[current].focus();
    }
  }

  function focusOnPrevItem() {
    let current = -1;
    const items = menu.current.querySelectorAll(
      '[role="menuitem"]:not([tabindex="-1"])'
    );

    Array.from(items).forEach((item, i) => {
      if (item === document.activeElement) {
        current = i;
      }
    });

    current -= 1;

    if (current < 0) {
      current = items.length - 1;
    }

    if (items[current] && enabled) {
      items[current].focus();
    }
  }

  function onLetter({ key }) {
    const items = menu.current.querySelectorAll(
      '[role="menuitem"]:not([tabindex="-1"])'
    );

    const filtered = Array.from(items).filter(node => {
      return node.textContent.substring(0, 1).toLowerCase() == key;
    });

    let index = filtered.findIndex(node => {
      return node == document.activeElement;
    });

    index = index + 1 > filtered.length - 1 ? 0 : index + 1;

    if (filtered[index]) {
      filtered[index].focus();
    }
  }

  letters.forEach(letter => {
    letterKeys[letter] = onLetter;
  });

  useKeys(`${key}-button`, enabled && active && !open, {
    enter: e => {
      e.preventDefault();
      setOpen(true);
      focusOnFirstItem();
    },
    space: e => {
      e.preventDefault();
      setOpen(true);
      focusOnFirstItem();
    },
    down: e => {
      e.preventDefault();
      setOpen(true);
      focusOnNextItem();
    },

    up: e => {
      e.preventDefault();
      setOpen(true);
      focusOnPrevItem();
    },
  });

  useKeys(`${key}-container`, enabled && open, {
    esc: e => {
      e.preventDefault();
      setOpen(false);
    },
    down: e => {
      e.preventDefault();
      focusOnNextItem();
    },

    up: e => {
      e.preventDefault();
      focusOnPrevItem();
    },

    home: e => {
      e.preventDefault();
      focusOnFirstItem();
    },

    end: e => {
      e.preventDefault();
      focusOnLastItem();
    },

    ...letterKeys,
  });

  useEffect(() => {
    if (!isMount && button && enabled) {
      if (!open) {
        if (button.current) {
          button.current.focus();
        }
      }
    }
  }, [enabled, open]);

  return [button, menu, open, setOpen];
};
