/*
 * This file is part of the Nsr Stem Utdanning 2022 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Item.scss';

/**
 * This is the Item component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
function Item({ id, disabled, open, active, name, items, onClick }) {
  const refContent = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    function onResize() {
      if (open) {
        setHeight(refContent.current.offsetHeight + 'px');
      }
    }

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
      setHeight('0px');
    };
  }, [open]);

  return (
    <div className={classnames('item', { active })}>
      <button
        role="menuitem"
        tabIndex={disabled ? '-1' : null}
        aria-haspopup="true"
        aria-controls={`item-${id}`}
        aria-expanded={open ? 'true' : 'false'}
        onClick={onClick}
      >
        {name}
      </button>
      <div id={`item-${id}`} className="items" style={{ height }}>
        <div ref={refContent}>
          {items.map(({ id, name, url, active }) => (
            <a
              key={id}
              href={url}
              role="menuitem"
              tabIndex={open ? null : '-1'}
              className={classnames({ active })}
            >
              {name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Item.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Item.defaultProps = {
  id: 'item',
  disabled: false,
  open: false,
  active: false,
  name: '',
  items: [],
  onClick: () => {},
};

export default Item;
