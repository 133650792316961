/*
 * This file is part of the Nsr Stem Utdanning 2022 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Magnifier from 'components/Icons/Magnifier/Magnifier';
import ExternalLink from 'components/Icons/ExternalLink/ExternalLink';
import Item from './Item/Item';
import './Menu.scss';

/**
 * This is the Menu component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
const Menu = forwardRef(({ open: menuIsOpen, search, items, xfactor }, ref) => {
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (!menuIsOpen) {
      setCurrent(null);
    }
  }, [menuIsOpen]);

  return (
    <div
      ref={ref}
      id="menu"
      role="menu"
      className="menu"
      aria-labelledby="menubutton"
    >
      <form action={search.action} method="GET">
        <div>
          <label htmlFor="frase">{search.label}</label>
          <input
            id="frase"
            name={search.name}
            placeholder={search.placeholder}
          />
          <button type="submit">
            <Magnifier title={search.button} />
          </button>
        </div>
      </form>
      <div className="content">
        {items.map(({ id, name, url, active, children }) => {
          if (children.length) {
            return (
              <Item
                key={id}
                id={id}
                name={name}
                url={url}
                disabled={!menuIsOpen}
                open={id === current}
                active={active}
                items={children}
                onClick={() => {
                  setCurrent(prev => {
                    if (prev === id) {
                      return null;
                    }
                    return id;
                  });
                }}
              />
            );
          }
          return (
            <a
              key={id}
              href={url}
              tabIndex={open ? null : '-1'}
              role="menuitem"
              className={classnames({ active })}
            >
              {name}
            </a>
          );
        })}
      </div>
      <div className="layout-padding xfactor">
        <a
          role="menuitem"
          className="external-link"
          href={xfactor.url}
          title={xfactor.title}
          tabIndex={open ? null : '-1'}
        >
          <span className="text">{xfactor.text}</span>
          <ExternalLink title={xfactor.title} />
        </a>
      </div>
    </div>
  );
});

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
Menu.propTypes = {
  open: PropTypes.bool,
  search: PropTypes.shape({
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          active: PropTypes.bool,
        })
      ).isRequired,
    })
  ).isRequired,
  xfactor: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
Menu.defaultProps = {
  open: false,
  items: [],
  search: {
    action: '/search',
    label: 'Search',
    button: 'Search',
    placeholder: 'Enter your search',
    name: 'q',
  },
  xfactor: {
    url: '',
    text: '',
    title: '',
  },
};

export default Menu;
