/*
 * This file is part of the Nsr Stem Utdanning 2022 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useEnhanced from 'hooks/enhanced';
import useMenu from 'hooks/menu';
import Hamburger from 'components/Hamburger/Hamburger';
import Magnifier from 'components/Icons/Magnifier/Magnifier';
import logo from '../../../media/gfx/stem-logo-black.svg';
import Menu from './Menu/Menu';
import './MainNav.scss';

/**
 * This is the MainNav component.
 *
 * @author Thomas Sømoen <thomas.somoen@try.no>
 *
 * @return {JSX}
 */
function MainNav({ home, items, languages, search, xfactor, labels }) {
  const enhanced = useEnhanced(1024);
  const [buttonRef, menuRef, open, setOpen] = useMenu('menu', !enhanced);

  useEffect(() => {
    if (!enhanced && open) {
      document.documentElement.classList.add('menu-open');
    }

    return () => {
      document.documentElement.classList.remove('menu-open');
    };
  }, [open, enhanced]);

  function renderItems() {
    if (!enhanced) return null;

    return (
      <div className="items">
        {items.map(({ id, url, name, active }) => (
          <a key={id} href={url} className={classNames({ active })}>
            {name}
          </a>
        ))}
      </div>
    );
  }

  function renderToggle() {
    if (enhanced) return null;
    return (
      <button
        ref={buttonRef}
        id="menubutton"
        className="toggle"
        aria-label={open ? labels.close : labels.open}
        aria-haspopup="true"
        aria-controls="menu"
        aria-expanded={open ? 'true' : 'false'}
      >
        <Hamburger open={open} />
      </button>
    );
  }

  function renderSearch() {
    if (!enhanced) return null;

    return (
      <form action={search.action} method="GET">
        <div>
          <label htmlFor="frase">{search.label}</label>
          <input
            id="frase"
            name={search.name}
            placeholder={search.placeholder}
          />
          <button type="submit">
            <Magnifier title={search.button} />
          </button>
        </div>
      </form>
    );
  }

  function renderMobileMenu() {
    if (enhanced) return null;
    return (
      <Menu
        ref={menuRef}
        search={search}
        items={items}
        xfactor={xfactor}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    );
  }

  function renderLanguages() {
    if (languages.length < 2) return null;

    return languages.map(({ id, short, title, url, active }) => (
      <a key={id} href={url} title={title} className={classNames({ active })}>
        {short}
      </a>
    ));
  }

  return (
    <>
      <nav className="layout-grid main-nav">
        <a className="home" href={home.url} title={home.title}>
          <img src={logo} width="205" height="32" alt={home.alt} />
        </a>
        {renderItems()}
        <div className="languages">{renderLanguages()}</div>
        {renderSearch()}
        {renderToggle()}
      </nav>
      {renderMobileMenu()}
    </>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
MainNav.propTypes = {
  home: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          active: PropTypes.bool,
        })
      ).isRequired,
    })
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      short: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  search: PropTypes.shape({
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  xfactor: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    open: PropTypes.string.isRequired,
    close: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
MainNav.defaultProps = {
  home: {
    url: '/',
    title: 'To frontpage',
    alt: '',
  },
  items: [],
  languages: [],
  search: {
    action: '/search',
    label: 'Search',
    button: 'Search',
    placeholder: 'Enter your search',
    name: 'q',
  },
  xfactor: {
    url: '',
    text: '',
    title: '',
  },
  labels: {
    open: 'Open menu',
    close: 'Open menu',
  },
};

export default MainNav;
