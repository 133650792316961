// import React from 'react';
import registerReact from 'utils/registerReact';

/**
 * React components
 */
import MainNav from 'components/MainNav/MainNav';

// Start registering  react component on custom tag names

export default () => {
  registerReact('main-nav-component', MainNav);
};

// Finish registering react components
