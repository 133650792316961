/**
 * This file is part of the Energima Nettside 2021 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import './styles/stem.scss';

function hasTouch() {
  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && window.document instanceof window.DocumentTouch)
  ) {
    return true;
  }

  const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-'];
  const media = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    ''
  );

  // window.matchMedia('(touch-enabled),(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-heartz)').matches
  return window.matchMedia(media).matches;
}

if (hasTouch()) {
  document.body.classList.remove('no-touch');
}

function setVH() {
  window.document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  );
}

global.addEventListener('resize', setVH);

setVH();

/* webpack-strip-block:removed */

  require('./scripts/stem.prod');
  /* webpack-strip-block:removed */
