import registerDecoration from 'utils/registerDecoration';
import homepageAudience from 'decorators/homepage-audience';
import anchorMenu from 'decorators/anchor-menu';

// Start registering  react component on custom tag names

export default () => {
  registerDecoration('.homepage .audience', homepageAudience);
  registerDecoration('.anchor-menu', anchorMenu);
};

// Finish registering react components
